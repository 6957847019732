// src/auth/AuthContext.jsx
import React, { createContext, useState, useCallback, useEffect, useContext } from 'react';
import { Navigate, useLocation, useNavigate, Outlet } from 'react-router-dom';
import { cloudflareAuth } from './cloudflare';

export const AuthContext = createContext(null);

const LOCAL_STORAGE_KEY = 'wondernet_internal_auth';

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [needsBootstrap, setNeedsBootstrap] = useState(false);

  // Initialize auth state from localStorage
  useEffect(() => {
    const initializeAuth = async () => {
      try {
        // First check if system needs bootstrapping
        const data = await cloudflareAuth.getStorageData();
        if (!data.users || data.users.length === 0) {
          setNeedsBootstrap(true);
          setLoading(false);
          return;
        }

        const storedAuth = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (storedAuth) {
          const { token, user: storedUser } = JSON.parse(storedAuth);
          // Verify token is still valid
          await cloudflareAuth.verifyToken(token);
          setUser(storedUser);
        }
      } catch (error) {
        console.error('Auth initialization error:', error);
        localStorage.removeItem(LOCAL_STORAGE_KEY);
      } finally {
        setLoading(false);
      }
    };

    initializeAuth();
  }, []);

  const login = useCallback(async (email, password) => {
    try {
      setError(null);
      setLoading(true);
      const { token, user } = await cloudflareAuth.authenticateUser(email, password);
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ token, user }));
      setUser(user);
      return true;
    } catch (error) {
      setError(error.message);
      return false;
    } finally {
      setLoading(false);
    }
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    setUser(null);
  }, []);

  const isAdmin = useCallback(() => {
    return user?.role === 'admin';
  }, [user]);

  const completeBootstrap = useCallback(() => {
    setNeedsBootstrap(false);
  }, []);

  const value = {
    user,
    loading,
    error,
    login,
    logout,
    isAdmin,
    setError,
    needsBootstrap,
    completeBootstrap
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="w-8 h-8 border-4 border-wondernet-600 border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const RequireAuth = ({ children }) => {
  const location = useLocation();
  const { user, needsBootstrap } = useContext(AuthContext);

  if (needsBootstrap) {
    return <Navigate to="/bootstrap" state={{ from: location }} replace />;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export const RequireAdmin = () => {
  const location = useLocation();
  const { user, isAdmin } = useContext(AuthContext);
  const navigate = useNavigate();

  if (!user || !isAdmin()) {
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  }

  return <Outlet />;
};