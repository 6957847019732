import React from 'react';
import { motion } from 'framer-motion';
import { AlertCircle, Home } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useAuth } from './useAuth';

const UnauthorizedHandler = () => {
  const { user } = useAuth();

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-md w-full"
      >
        <div className="text-center">
          <div className="inline-flex items-center justify-center w-16 h-16 bg-red-100 rounded-full mb-6">
            <AlertCircle className="w-8 h-8 text-red-600" />
          </div>
          <h1 className="text-3xl font-bold text-gray-900 mb-2">
            Access Denied
          </h1>
          <p className="text-gray-600 mb-8">
            {user 
              ? "You don't have permission to access this page."
              : "Please sign in to access this page."}
          </p>
          <Link
            to={user ? "/dashboard" : "/login"}
            className="inline-flex items-center px-6 py-3 bg-wondernet-600 text-white rounded-lg hover:bg-wondernet-500 transition-colors"
          >
            <Home className="w-5 h-5 mr-2" />
            {user ? "Return to Dashboard" : "Sign In"}
          </Link>
        </div>
      </motion.div>
    </div>
  );
};

export default UnauthorizedHandler;
