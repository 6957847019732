import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { RequireAuth, RequireAdmin } from './auth/AuthContext';
import DashboardLayout from './components/layout/DashboardLayout';
import { Spinner } from './components/common/Spinner';
import UnauthorizedHandler from './auth/UnauthorizedHandler';

// Lazy load pages
const Login = React.lazy(() => import('./pages/Login'));
const Bootstrap = React.lazy(() => import('./pages/Bootstrap'));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const Tools = React.lazy(() => import('./pages/Tools'));
const UserManagement = React.lazy(() => import('./pages/admin/UserManagement'));
const Settings = React.lazy(() => import('./pages/admin/Settings'));
const InternetDiagnostics = React.lazy(() => import('./pages/tools/InternetDiagnostics'));
const ActiveEthernetDiagnostics = React.lazy(() => import('./pages/tools/ActiveEthernetDiagnostics'));
const WonderWatchDownDetector = React.lazy(() => import('./pages/tools/WonderWatchDownDetector'));
const CustomersPage = React.lazy(() => import('./pages/customers/CustomersPage'));
const CustomerDetailPage = React.lazy(() => import('./pages/customers/CustomerDetailPage'));

const App = () => {
  return (
    <HelmetProvider>
      <Suspense fallback={<div className="min-h-screen flex items-center justify-center"><Spinner /></div>}>
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/bootstrap" element={<Bootstrap />} />
          <Route path="/unauthorized" element={<UnauthorizedHandler />} />

          {/* Protected Routes */}
          <Route element={<RequireAuth><DashboardLayout /></RequireAuth>}>
            <Route path="/" element={<Navigate to="/customers" replace />} />
            <Route path="/customers" element={<CustomersPage />} />
            <Route path="/customers/:id" element={<CustomerDetailPage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/tools" element={<Tools />} />
            
            {/* Tool Pages */}
            <Route path="/tools/internet-diagnostics" element={<InternetDiagnostics />} />
            <Route path="/tools/active-ethernet-diagnostics" element={<ActiveEthernetDiagnostics />} />
            <Route path="/tools/wonderwatch-down-detector" element={<WonderWatchDownDetector />} />
            
            {/* Admin Routes */}
            <Route element={<RequireAdmin />}>
              <Route path="/admin/users" element={<UserManagement />} />
              <Route path="/admin/settings" element={<Settings />} />
            </Route>

          </Route>

          {/* Catch all */}
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </Suspense>
    </HelmetProvider>
  );
};

export default App;