import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { 
  Home, Grid, Users, Settings, X, Wrench, FileText,
  Globe, Mail, Calendar, HelpCircle, Headphones,
  Database, Monitor, Cloud, UserPlus
} from 'lucide-react';
import { useAuth } from '../../auth/useAuth';

const NavItem = ({ to, icon: Icon, children, external }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  const baseClasses = `
    flex items-center space-x-2 px-3 py-2 rounded-lg text-sm font-medium
    transition-colors duration-150 group relative
    ${isActive 
      ? 'bg-wondernet-50 text-wondernet-600' 
      : 'text-gray-700 hover:bg-gray-50'
    }
  `;

  if (external) {
    return (
      <a 
        href={to} 
        target="_blank" 
        rel="noopener noreferrer"
        className={baseClasses}
      >
        <Icon className="w-5 h-5" />
        <span>{children}</span>
        <Globe className="w-3 h-3 ml-auto text-gray-400" />
      </a>
    );
  }

  return (
    <NavLink to={to} className={baseClasses}>
      <Icon className="w-5 h-5" />
      <span>{children}</span>
      {isActive && (
        <span className="absolute inset-y-0 left-0 w-1 bg-wondernet-600 rounded-r-full" />
      )}
    </NavLink>
  );
};

const NavSection = ({ title, children }) => (
  <div className="space-y-1">
    <h3 className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider">
      {title}
    </h3>
    {children}
  </div>
);

const Sidebar = ({ onClose }) => {
  const { isAdmin } = useAuth();

  return (
    <div className="h-full flex flex-col bg-white">
      {/* Mobile close button */}
      {onClose && (
        <div className="p-4 lg:hidden">
          <button
            onClick={onClose}
            className="p-2 text-gray-500 hover:text-gray-900"
          >
            <X className="w-6 h-6" />
          </button>
        </div>
      )}

      {/* Logo */}
      <div className="p-4">
        <img
          src="https://apextech.group/wondernet-logo-colour.png"
          alt="Wondernet"
          className="h-8"
        />
      </div>

      {/* Navigation */}
      <nav className="flex-1 px-3 pb-4 space-y-6 overflow-y-auto">
        <NavSection title="Overview">
          <NavItem to="/dashboard" icon={Home}>
            Dashboard
          </NavItem>
          <NavItem to="/tools" icon={Grid}>
            Tools Grid
          </NavItem>
        </NavSection>

        <NavSection title="Internal Tools">
          <NavItem to="https://billing.wondernet.co.za" icon={Database} external>
            Billing System
          </NavItem>
          <NavItem to="https://gigawave.qcontact.com" icon={Headphones} external>
            Case Management
          </NavItem>
          <NavItem to="https://portal.gigawave.co.za/admin" icon={Settings} external>
            OSS/BSS
          </NavItem>
          <NavItem to="/customers" icon={UserPlus}>
            Customer Management
          </NavItem>
          <NavItem to="/tools/internet-diagnostics" icon={Wrench}>
            Internet Down Diagnostics
          </NavItem>
          <NavItem to="/tools/active-ethernet-diagnostics" icon={Monitor}>
            Active Ethernet Diagnostics
          </NavItem>
          <NavItem to="/tools/wonderwatch-down-detector" icon={Cloud}>
            WonderWatch Down Detector
          </NavItem>
          <NavItem to="https://mail.wondernet.co.za" icon={Mail} external>
            Email System
          </NavItem>
          <NavItem to="https://crm.wondernet.co.za" icon={Users} external>
            CRM
          </NavItem>
          <NavItem to="http://librenms.gigawave.co.za/devices" icon={Monitor} external>
            System Monitoring
          </NavItem>
          <NavItem to="https://cloud.wondernet.co.za" icon={Cloud} external>
            Cloud Services
          </NavItem>
        </NavSection>

        <NavSection title="Resources">
          <NavItem to="/documents" icon={FileText}>
            Documents
          </NavItem>
          <NavItem to="/calendar" icon={Calendar}>
            Company Calendar
          </NavItem>
          <NavItem to="/knowledge-base" icon={HelpCircle}>
            Knowledge Base
          </NavItem>
        </NavSection>

        {isAdmin && (
          <NavSection title="Admin">
            <NavItem to="/admin/users" icon={Users}>
              User Management
            </NavItem>
            <NavItem to="/admin/settings" icon={Settings}>
              Portal Settings
            </NavItem>
          </NavSection>
        )}
      </nav>

      {/* Status Indicator */}
      <div className="p-4 border-t">
        <div className="flex items-center">
          <div className="w-2 h-2 bg-green-500 rounded-full" />
          <span className="ml-2 text-sm text-gray-600">Systems Operational</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
