// src/auth/cloudflare.js
import { jwtVerify, SignJWT } from 'jose';

const JWT_SECRET = new TextEncoder().encode(import.meta.env.VITE_JWT_SECRET);
const JWT_EXPIRY = '8h';
const API_BASE = import.meta.env.DEV ? 'http://localhost:8788' : '';

export class CloudflareAuth {
  async getStorageData() {
    try {
      const response = await fetch(`${API_BASE}/api/auth/users`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        let error;
        try {
          error = JSON.parse(errorText);
        } catch {
          error = { error: 'Invalid response from server' };
        }
        throw new Error(error.error || 'Failed to fetch users data');
      }

      const text = await response.text();
      if (!text) return { users: [] };
      
      try {
        return JSON.parse(text);
      } catch (error) {
        console.error('Invalid JSON response:', text);
        return { users: [] };
      }
    } catch (error) {
      console.error('Error fetching storage data:', error);
      throw error;
    }
  }

  async updateStorageData(data) {
    try {
      const response = await fetch(`${API_BASE}/api/auth/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorText = await response.text();
        let error;
        try {
          error = JSON.parse(errorText);
        } catch {
          error = { error: 'Invalid response from server' };
        }
        throw new Error(error.error || 'Failed to update users data');
      }

      return true;
    } catch (error) {
      console.error('Error updating storage data:', error);
      throw error;
    }
  }

  async authenticateUser(email, password) {
    try {
      const data = await this.getStorageData();
      const user = data.users.find(
        (u) => u.email === email && u.password === password && u.status === 'active'
      );

      if (!user) {
        throw new Error('Invalid credentials');
      }

      const token = await new SignJWT({
        sub: user.id,
        email: user.email,
        role: user.role,
      })
        .setProtectedHeader({ alg: 'HS256' })
        .setIssuedAt()
        .setExpirationTime(JWT_EXPIRY)
        .sign(JWT_SECRET);

      return {
        token,
        user: {
          id: user.id,
          name: user.name,
          email: user.email,
          role: user.role,
        },
      };
    } catch (error) {
      console.error('Authentication error:', error);
      throw error;
    }
  }

  async verifyToken(token) {
    try {
      const { payload } = await jwtVerify(token, JWT_SECRET);
      const data = await this.getStorageData();
      const user = data.users.find(
        (u) => u.id === payload.sub && u.status === 'active'
      );

      if (!user) {
        throw new Error('User not found or inactive');
      }

      return {
        id: user.id,
        name: user.name,
        email: user.email,
        role: user.role,
      };
    } catch (error) {
      console.error('Token verification error:', error);
      throw error;
    }
  }

  async createUser(userData) {
    try {
      const data = await this.getStorageData();
      
      // Check if user already exists
      if (data.users.some(u => u.email === userData.email)) {
        throw new Error('User already exists');
      }

      // Add new user
      const newUsers = {
        users: [...data.users, { ...userData, createdAt: new Date().toISOString() }]
      };

      await this.updateStorageData(newUsers);
      return true;
    } catch (error) {
      console.error('Error creating user:', error);
      throw error;
    }
  }

  async updateUser(email, updates) {
    try {
      const data = await this.getStorageData();
      const userIndex = data.users.findIndex(u => u.email === email);

      if (userIndex === -1) {
        throw new Error('User not found');
      }

      data.users[userIndex] = { ...data.users[userIndex], ...updates, updatedAt: new Date().toISOString() };
      await this.updateStorageData({ users: data.users });
      return true;
    } catch (error) {
      console.error('Error updating user:', error);
      throw error;
    }
  }

  async deleteUser(email) {
    try {
      const data = await this.getStorageData();
      const newUsers = {
        users: data.users.filter(u => u.email !== email)
      };

      await this.updateStorageData(newUsers);
      return true;
    } catch (error) {
      console.error('Error deleting user:', error);
      throw error;
    }
  }

  async resetPassword(email, newPassword) {
    try {
      const data = await this.getStorageData();
      const userIndex = data.users.findIndex(u => u.email === email);

      if (userIndex === -1) {
        throw new Error('User not found');
      }

      data.users[userIndex] = {
        ...data.users[userIndex],
        password: newPassword,
        passwordResetAt: new Date().toISOString()
      };

      await this.updateStorageData({ users: data.users });
      return true;
    } catch (error) {
      console.error('Error resetting password:', error);
      throw error;
    }
  }

  async bootstrapSystem() {
    try {
      const response = await fetch(`${API_BASE}/api/auth/bootstrap`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        const errorText = await response.text();
        let error;
        try {
          error = JSON.parse(errorText);
        } catch {
          error = { error: 'Invalid response from server' };
        }
        throw new Error(error.error || 'Failed to bootstrap system');
      }

      return true;
    } catch (error) {
      console.error('Bootstrap error:', error);
      throw error;
    }
  }
}

export const cloudflareAuth = new CloudflareAuth();